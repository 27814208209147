main {
  display: flex;
  flex-direction: column;
}
.popup {
  top:0;
  left: 0;
  background-color: azure;
  display: flex;
  justify-content: center;
  align-items: center;
  
  }
  
  .popup-inner {
      position: relative;
      padding: 32px;
      width: 100%;
  }
  .popup-inner .close-btn {
      position: absolute;
      top: 16px;
      right: 16px;
  
  }